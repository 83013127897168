import {
  useEffect,
  useRef,
  useState,
  type ReactNode,
  type UIEvent,
} from "react";
import GoldText, {
  $goldBackgroundClassName,
  $goldTextClassName,
} from "./goldText";

type SlideProps = {
  backgroundName?: string;
  children: ReactNode | ReactNode[];
  className?: string;
};
function Slide({
  backgroundName,
  children,
  className: extraClassName = "",
}: SlideProps) {
  let className = `${extraClassName}`;
  let style = {};
  if (backgroundName) {
    className += ` bg-no-repeat`;
    style = {
      backgroundImage: `url('/survey-slides/${backgroundName}')`,
      backgroundSize: "auto 80%",
    };
  }
  return (
    <div
      style={style}
      className={`h-full w-full flex flex-col bg-cover py-4 px-[10%] justify-center ${className}`}
    >
      {children}
    </div>
  );
}

function SlideContent({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col gap-8 p-2 border border-gray-500 bg-black/80 lg:gap-14 xl:border-0 xl:bg-transparent">
      {children}
    </div>
  );
}

type ProgressBarProps = { activeIndex: number; slideCount: number };
function ProgressBar({ activeIndex, slideCount }: ProgressBarProps) {
  if (activeIndex < 0) {
    return null;
  }
  const allIndexes = Array.from({ length: slideCount }, (_, i) => i);
  const leftClass = activeIndex === 0 ? "-left-12" : "left-0";
  return (
    <div
      className={`fixed w-12 top-0 bottom-0 gap-14 flex flex-col justify-center transition-all ${leftClass}`}
    >
      {allIndexes.map((index) => {
        const widthClass = index === activeIndex ? "w-full" : "w-5";
        const bgClass =
          index === activeIndex
            ? "bg-gold"
            : index < activeIndex
            ? "bg-white"
            : "bg-neutral-700";
        return (
          <div
            key={index}
            className={`h-1 transition-all duration-700 ${widthClass} ${bgClass}`}
          />
        );
      })}
    </div>
  );
}

type ButtonProps = { children: ReactNode; onClick: () => void };
function Button({ children, onClick }: ButtonProps) {
  return (
    <button
      className={`text-black py-2 px-8 rounded-md self-start ${$goldBackgroundClassName}`}
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      {children}
    </button>
  );
}

type RadioButtonProps = {
  name: string;
  children: string;
  currentValue: string;
  onSelect: (value: string) => void;
};
function RadioButton({
  children,
  name,
  currentValue,
  onSelect,
}: RadioButtonProps) {
  const checked = children === currentValue;
  const labelClasses = `flex items-center gap-4 text-2xl ${
    checked ? "text-gold" : "text-white"
  }`;
  const inputClasses =
    "appearance-none border-2 border-white rounded-full h-6 w-6 checked:bg-gold checked:border-gold";
  return (
    <label className={labelClasses}>
      <input
        className={inputClasses}
        type="radio"
        name={name}
        value={children}
        onClick={() => onSelect(children)}
      />
      {children}
    </label>
  );
}

type SlideHeaderProps = { children: string };
function SlideHeader({ children }: SlideHeaderProps) {
  return (
    <h1 className={`font-laterlocks text-3xl tracking-widest`}>{children}</h1>
  );
}

export default function SurveyPage() {
  const slideCount = 6;

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollingDiv = useRef<HTMLDivElement | null>(null);

  const [frequency, setFrequency] = useState("");
  const [channel, setChannel] = useState("");
  const [serviceClass, setServiceClass] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const textInputClasses = `rounded-md p-4 flex-1 bg-black border-neutral-700 border-2`;

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight } = event.currentTarget;
    const slideHeight = scrollHeight / slideCount;
    const currentIndex = Math.floor(scrollTop / slideHeight);
    const offsetWithinSlide = scrollTop % slideHeight;
    if (scrollTop > lastScrollTop && offsetWithinSlide > 0) {
      // scrolling down
      setActiveIndex(Math.min(slideCount - 1, currentIndex + 1));
    } else if (scrollTop < lastScrollTop) {
      // scrolling up
      setActiveIndex(Math.max(0, currentIndex));
    }
    setLastScrollTop(scrollTop);
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const response = await fetch("/api/saveSurvey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        frequency,
        channel,
        serviceClass,
      }),
    });
    if (response.ok) {
      setActiveIndex(0);
      setFrequency("");
      setChannel("");
      setServiceClass("");
      setFirstName("");
      setLastName("");
      setEmail("");
      alert("Thank you for completing the survey!");
    }
  };

  useEffect(() => {
    if (!scrollingDiv.current) {
      return;
    }
    const slideHeight = scrollingDiv.current.scrollHeight / slideCount;
    scrollingDiv.current?.scrollTo({
      left: 0,
      top: activeIndex * slideHeight,
      behavior: "smooth",
    });
  }, [activeIndex]);

  return (
    <>
      <form>
        <div
          className="w-full h-screen overflow-auto"
          onScroll={handleScroll}
          ref={scrollingDiv}
        >
          <div
            className={`p-4 fixed flex items-center justify-center start-0 end-0 transition-all duration-700 ${
              activeIndex === 0 ? "min-h-[50%]" : "min-h-0"
            }`}
          >
            <img
              src="/logo.svg"
              alt="Jet Setter"
              className={`transition-all duration-700 ${
                activeIndex === 0 ? "max-h-24" : "max-h-16"
              }`}
            />
          </div>
          <Slide className="justify-between">
            <div className="h-16" />
            <h1
              className={`text-center text-6xl lg:text-8xl font-laterlocks ${$goldTextClassName}`}
            >
              Explore. Discover. Experience.
            </h1>
            <img
              src="/triangle-arrow-up-broken.svg"
              className="h-32"
              onClick={() => setActiveIndex(1)}
            />
          </Slide>
          <Slide backgroundName="slide-2-bg-alt.png" className="bg-left-bottom">
            <SlideContent>
              <p className="self-end max-w-2xl text-3xl leading-relaxed tracking-wide lg:text-right">
                We are Jet Setter, our mission is to provide you a{" "}
                <GoldText>luxurious</GoldText> experience of booking trips. To
                make the best of our services, we would like to hear about you!
                Please take a few minutes to complete the survey below. Thank
                you!
              </p>
            </SlideContent>
          </Slide>
          <Slide
            backgroundName="slide-3-bg-alt.png"
            className="bg-right-bottom"
          >
            <SlideContent>
              <SlideHeader>1. How often do you travel per year?</SlideHeader>
              <RadioButton
                name="frequency"
                currentValue={frequency}
                onSelect={setFrequency}
              >
                1 to 4 trips
              </RadioButton>
              <RadioButton
                name="frequency"
                currentValue={frequency}
                onSelect={setFrequency}
              >
                5 to 8 trips
              </RadioButton>
              <RadioButton
                name="frequency"
                currentValue={frequency}
                onSelect={setFrequency}
              >
                9 to 12 trips
              </RadioButton>
              <RadioButton
                name="frequency"
                currentValue={frequency}
                onSelect={setFrequency}
              >
                More than 12 trips
              </RadioButton>
              <Button onClick={() => setActiveIndex(3)}>Next</Button>
            </SlideContent>
          </Slide>
          <Slide
            backgroundName="slide-4-bg-alt.png"
            className="bg-right-bottom"
          >
            <SlideContent>
              <SlideHeader>2. How do you book your travel?</SlideHeader>
              <RadioButton
                name="channel"
                currentValue={channel}
                onSelect={setChannel}
              >
                Mobile app
              </RadioButton>
              <RadioButton
                name="channel"
                currentValue={channel}
                onSelect={setChannel}
              >
                Website
              </RadioButton>
              <RadioButton
                name="channel"
                currentValue={channel}
                onSelect={setChannel}
              >
                Travel agent
              </RadioButton>
              <Button onClick={() => setActiveIndex(4)}>Next</Button>
            </SlideContent>
          </Slide>
          <Slide
            backgroundName="slide-5-bg-alt.png"
            className="bg-right-bottom"
          >
            <SlideContent>
              <SlideHeader>3. What is your travel preference?</SlideHeader>
              <RadioButton
                name="serviceClass"
                currentValue={serviceClass}
                onSelect={setServiceClass}
              >
                First or Business class
              </RadioButton>
              <RadioButton
                name="serviceClass"
                currentValue={serviceClass}
                onSelect={setServiceClass}
              >
                Economy
              </RadioButton>
              <RadioButton
                name="serviceClass"
                currentValue={serviceClass}
                onSelect={setServiceClass}
              >
                Some of both
              </RadioButton>
              <Button onClick={() => setActiveIndex(5)}>Next</Button>
            </SlideContent>
          </Slide>
          <Slide
            backgroundName="slide-6-bg-alt.png"
            className="bg-right-bottom"
          >
            <SlideContent>
              <SlideHeader>Provide your name and email</SlideHeader>
              <div className="flex flex-col max-w-2xl gap-4">
                <div className="flex flex-col xl:flex-row gap-4">
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    className={textInputClasses}
                  />
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    className={textInputClasses}
                  />
                </div>
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="your@email.com"
                    className={textInputClasses}
                  />
                </div>
              </div>
              <Button onClick={handleSubmit}>Submit</Button>
            </SlideContent>
          </Slide>
        </div>
      </form>
      <ProgressBar activeIndex={activeIndex - 1} slideCount={slideCount - 1} />
    </>
  );
}
