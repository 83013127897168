import { createElement } from "react";

type Props = { children: string; element?: keyof JSX.IntrinsicElements };
export const $goldBackgroundClassName =
  "bg-gradient-to-b from-gold1 via-gold2 via-gold3 to-gold4";
export const $goldTextClassName = `bg-clip-text text-transparent ${$goldBackgroundClassName}`;
export default function GoldText({ children, element }: Props) {
  return createElement(
    element || "span",
    { className: $goldTextClassName },
    children
  );
}
